body {
    background-color: #FFFDD7;
background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1600 900'%3E%3Cdefs%3E%3ClinearGradient id='a' x1='0' x2='0' y1='1' y2='0' gradientTransform='rotate(131,0.5,0.5)'%3E%3Cstop offset='0' stop-color='%230FF'/%3E%3Cstop offset='1' stop-color='%23CF6'/%3E%3C/linearGradient%3E%3ClinearGradient id='b' x1='0' x2='0' y1='0' y2='1' gradientTransform='rotate(82,0.5,0.5)'%3E%3Cstop offset='0' stop-color='%23FF6D54'/%3E%3Cstop offset='1' stop-color='%23FC0'/%3E%3C/linearGradient%3E%3C/defs%3E%3Cg fill='%23FFF' fill-opacity='0' stroke-miterlimit='10'%3E%3Cg stroke='url(%23a)' stroke-width='9.569999999999999'%3E%3Cpath transform='translate(-72.45 2) rotate(-2.6500000000000004 1409 581) scale(0.9505819999999999)' d='M1409 581 1450.35 511 1490 581z'/%3E%3Ccircle stroke-width='3.19' transform='translate(-96 57) rotate(4.1000000000000005 800 450) scale(1.002792)' cx='500' cy='100' r='40'/%3E%3Cpath transform='translate(9.7 -54) rotate(28 401 736) scale(1.002792)' d='M400.86 735.5h-83.73c0-23.12 18.74-41.87 41.87-41.87S400.86 712.38 400.86 735.5z'/%3E%3C/g%3E%3Cg stroke='url(%23b)' stroke-width='2.9'%3E%3Cpath transform='translate(342 3.1999999999999993) rotate(-0.7999999999999998 150 345) scale(0.994194)' d='M149.8 345.2 118.4 389.8 149.8 434.4 181.2 389.8z'/%3E%3Crect stroke-width='6.38' transform='translate(-85 -155.5) rotate(18 1089 759)' x='1039' y='709' width='100' height='100'/%3E%3Cpath transform='translate(-196.4 51.6) rotate(3.0000000000000018 1400 132) scale(0.87)' d='M1426.8 132.4 1405.7 168.8 1363.7 168.8 1342.7 132.4 1363.7 96 1405.7 96z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
background-attachment: fixed;
background-size: cover;
}
a {
    text-decoration: none !important;
    color: rgb(255, 247, 247) !important;
}

.main-table {
    width: 95% !important;
    margin: auto !important;
}
.main-table button {
    margin: 5px;
}

.main-form {
    width: 80%;
    margin: auto;
    margin-top: 100px;
    border: 3px solid rgb(115, 117, 240);
    padding: 10px;
    border-radius: 10px;
}
.main-form button {
    margin: 0 50%;
}

h1 {
    margin: auto;
    width: 50%;
}

table {
    text-align: center;

}

.dataTables_paginate {
    color: black !important;
}

.info {
    margin: auto;
    width: 50%;
}